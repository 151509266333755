body {
  color: white;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100vh;
  background-color: rgb(20, 20, 20);
  font-family: "Playfair Display", serif;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  letter-spacing: 1px;
}

html {
  scroll-behavior: smooth;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  color: inherit;
  text-decoration: none;
}

svg {
  width: 24px;
  height: 24px;
}

p {
  margin: 0;
}

h4 {
  margin-top: 0;
}
