@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;700&display=swap");

.fade-in-section {
  opacity: 0;
  transition: opacity 2s ease-in-out;
}

.fade-in-section.is-visible {
  opacity: 1;
}

button[aria-label="Go to next slide"] {
  z-index: 0;
}

button[aria-label="Go to previous slide"] {
  z-index: 0;
}
